// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~react-big-calendar/lib/sass/styles.scss';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';
@import '~react-quill/dist/quill.snow.css';
@import '~@smastrom/react-rating/style.css';
@import '~react-toastify/dist/ReactToastify.css';

#root {
  display: contents;
}
